const profileFactory = function ($resource) {
    const retriveContactApi =
        "/participant-web-services/rest/partialauth/profile/retriveProfileInfo";
    const retrieveUserNameForOneidApi =
        "/participant-web-services/rest/partialauth/profile/retrieveUserName";
    const updatePersonalContactApi =
        "/participant-web-services/rest/partialauth/profile/updatePersonalContact";
    const updateCredentialsApi =
        "/participant-web-services/rest/partialauth/profile/updateCredentials";
    const retrieveUsernameApi = "/participant-web-services/rest/partialauth/getUsername";
    const forceChangePasswordApi = "rest/partialauth/updateForceChangePassword";
    const retrieveAuthorizedDevicesApi = "/participant-web-services/rest/auth/authorizedDevices";
    const updateAuthorizedDevicesApi =
        "/participant-web-services/rest/auth/saveAuthorizedDeviceChanges";

    let username = "";
    let econsentEmail = "";
    let secondaryEmail = "";
    let sourcepage = "";

    let firstLineMailing = "";
    let scndLineMailing = "";
    let city = "";
    let state = "";
    let country = "";
    let zipcode = "";

    let eConsentDetails = {};
    let taxStatementEConsentDetails = {};

    let authorizedDevices = [];

    return {
        getSourcepage: function () {
            return sourcepage;
        },
        setSourcepage: function (page) {
            sourcepage = page;
        },

        getUsername: function () {
            return username;
        },
        setUsername: function (name) {
            username = name;
        },

        getEconsentEmail: function () {
            return econsentEmail;
        },
        setEconsentEmail: function (myeconsentEmail) {
            econsentEmail = myeconsentEmail;
        },

        getSecondaryEmail: function () {
            return secondaryEmail;
        },
        setSecondaryEmail: function (mysecondaryEmail) {
            secondaryEmail = mysecondaryEmail;
        },

        getEConsentDetails: function () {
            return eConsentDetails;
        },
        setEConsentDetails: function (myeConsentDetails) {
            eConsentDetails = myeConsentDetails;
        },
        getTaxStatementEConsentDetails: function () {
            return taxStatementEConsentDetails;
        },
        setTaxStatementEConsentDetails: function (myTaxStatementEConsentDetails) {
            taxStatementEConsentDetails = myTaxStatementEConsentDetails;
        },
        getFirstLineMailing: function () {
            return firstLineMailing;
        },
        setFirstLineMailing: function (myfirstLineMailing) {
            firstLineMailing = myfirstLineMailing;
        },
        getScndLineMailing: function () {
            return scndLineMailing;
        },
        setScndLineMailing: function (myscndLineMailing) {
            scndLineMailing = myscndLineMailing;
        },
        getCity: function () {
            return city;
        },
        setCity: function (mycity) {
            city = mycity;
        },
        getState: function () {
            return state;
        },
        setState: function (mystate) {
            state = mystate;
        },
        getCountry: function () {
            return country;
        },
        setCountry: function (mycountry) {
            country = mycountry;
        },
        getZipcode: function () {
            return zipcode;
        },
        setZipcode: function (myzipcode) {
            zipcode = myzipcode;
        },
        setAuthorizedDevices: function (devices) {
            authorizedDevices = devices;
        },
        getAuthorizedDevices: function () {
            return authorizedDevices;
        },

        retriveContactInfor: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retriveContactApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        updatePersonalContact: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + updatePersonalContactApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        updateCredentials: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + updateCredentialsApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        updateForceChangePassword: $resource(
            // eslint-disable-next-line no-undef
            __authenticationUrl__ + forceChangePasswordApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        ),

        retrieveUserNameForOneid: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveUserNameForOneidApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        retrieveUsername: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveUsernameApi,
            {},
            {
                query: {
                    method: "GET"
                }
            }
        ),

        retrieveAuthorizedDevices: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + retrieveAuthorizedDevicesApi,
            {},
            {
                query: {
                    method: "GET",
                    isArray: false
                }
            }
        ),

        updateAuthorizedDevices: $resource(
            // eslint-disable-next-line no-undef
            __baseApiUrl__ + updateAuthorizedDevicesApi,
            {},
            {
                query: {
                    method: "POST",
                    isArray: false,
                    headers: {
                        "Content-Type": "application/json"
                    }
                }
            }
        )
    };
};

profileFactory.$inject = ["$resource"];
export default profileFactory;
